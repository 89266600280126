@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

.img {
  @apply w-full object-cover;
}
.label {
  @apply text-sm font-light text-[#828282] capitalize;
}
.input {
  @apply p-2 rounded-xl font-light text-sm outline-none bg-white border focus:border-[#481053] focus:bg-white;
}
.menu {
  @apply flex items-center gap-4 px-6 py-5 text-[15px] text-[#8A9099] capitalize font-medium;
}
.active {
  @apply flex items-center gap-4 text-[15px] px-6 py-5  text-[#481053] capitalize font-semibold border-l-4 bg-[#F8F8F8] border-[#C6A533];
}
.report-container {
  @apply flex items-center p-4 gap-4 w-[23.5%] h-[100px] bg-white rounded-xl;
}
.icon-container {
  @apply w-[3rem] h-[3rem] rounded-full flex items-center justify-center;
}
.report-title {
  @apply text-[14px] font-normal capitalize text-[#8A9099];
}
.report-text {
  @apply text-[25px] text-[#3F434A] font-bold;
}
.submission-title {
  @apply w-[20%] font-medium text-[#8A9099] uppercase text-center;
}
.submission-text {
  @apply w-[20%];
}
.text {
  @apply font-medium text-[15px] text-center;
}
.report-btn {
  @apply text-base font-normal text-[#667085] p-2 hover:opacity-80  transition-all duration-300;
}
.input-wrapper {
  @apply w-full flex items-center justify-between gap-8;
}
.input-container {
  @apply w-full lg:w-[50%]  flex flex-col gap-2;
}
.input-container-full {
  @apply w-full flex flex-col gap-2;
}
.label {
  @apply text-[14px] font-normal uppercase h-[20px] text-[#515262];
}
.input {
  @apply outline-none p-2 rounded-xl w-full border;
}
/* scroll bar */
::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  /* border-radius: 10px; */
  height: 100%;
  width: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  margin: 0;
}
